<template>
  <section class="main-page">
    <section class="page-section mb-3">
      <div class="container">
        <div class="card">
          <div class="text-2xl text-primary font-bold">
            {{ $t("home") }}
          </div>
        </div>
      </div>
    </section>

    
        <section v-if="deadLine" class="page-section mb-3 ">
          <div class="container">
            <div class="card bg-blue-100">
              <div class=" text-primary font-bold">
                Please note that this voucher purchased is valid for the {{ user.academic_year }} only! It will expire on the {{ deadLine }}.
              </div>
            </div>
          </div>
        </section>
     



    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-6 sm:col-12 comp-grid">
            <div class="sticky-item">
              <div class="">
                <div class="card nice-shadow-18">
                  <small class="text-gray-500">
                    {{ $utils.humanDate($utils.dateTimeNow()) }}
                  </small>
                  <div class="text-2xl font-bold mt-1">
                    {{ $t("welcome_to_gctu") }}
                  </div>
                  <div v-if="currentAppConfig" class="">
                    <Divider />
                    <div class="">
                      <div class="my-3">
                        <div v-if="user.application_ref" class="mb-2">
                          <Avatar class="hidden-xs" style="background-color: #ffffff; color: #292866" icon="pi pi-user"
                            shape="circle" size="small" />
                          <span class="ml-2">{{ $t("Application Number") }}:</span>
                          <span class="ml-1 font-bold">
                            {{ user.application_ref }}</span>
                        </div>
                        <div v-if="user.application_ref" class="mb-2">
                          <Avatar class="hidden-xs" style="background-color: #ffffff; color: #292866" icon="pi pi-key"
                            shape="circle" size="small" />
                          <span class="ml-2">{{ $t("Serial Number") }}:</span>
                          <span class="ml-1 font-bold">
                            {{ user.serial_number }}</span>
                        </div>
                        <div v-if="user.academic_year" class="mb-2">
                          <Avatar class="hidden-xs" style="background-color: #ffffff; color: #292866"
                            icon="pi pi-calendar" shape="circle" size="small" />
                          <span class="ml-2">{{ $t("academic_year") }}:</span>
                          <span class="ml-1 font-bold">
                            {{ user.academic_year }},
                            {{ user.academic_month }}</span>
                        </div>
                        <div class="mb-2">
                          <Avatar class="hidden-xs" style="background-color: #ffffff; color: #292866" icon="pi pi-file"
                            shape="circle" size="small" />
                          <span class="ml-2">{{ $t("form_type") }}: </span>
                          <span class="ml-1 font-bold">
                            {{ user.application_type }}</span>
                        </div>
                        <div v-if="user.application_comments" class="mt-3">
                          <div class="card bg-pink-100">
                            <div class="flex">
                              <div class="mr-3">
                                <Avatar class="hidden-xs" style="
                                    background-color: #ffffff;
                                    color: #000000;
                                  " icon="pi pi-exclamation-triangle" shape="circle" size="large" />
                              </div>
                              <div class="mb-2">
                                <span class="text-md">
                                  {{ user.application_comments }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      <Divider />
                      <table class="p-datatable-table w-full">
                        <tbody>
                          <tr v-for="n in 3" :key="n">
                            <td class="text-left">
                              <Skeleton height="2rem" class="mx-2 my-1" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Divider />
                    </div>
                  </div>
                  <div v-if="user.application_status != closed" class="">
                    <router-link to="/applicants/edit">
                      <Button :class="user.application_status != 'Completed'
                        ? ''
                        : 'p-button-success'
                        " :label="user.application_status != 'Completed'
    ? $t('open_application_form')
    : $t('application_summary')
    " class="w-full" />
                    </router-link>
                  </div>
                  <div v-else>
                    <div class="card bg-pink-100 font-bold text-pink-600 mb-0">
                      {{ $t('Application Closed') }}
                    </div>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-12 md:col-6 sm:col-6">
                    <router-link :to="`applicants/edit`">
                      <div class="card bg-indigo-100">
                        <div class="text-center">
                          <div class="mb-3" style="width: auto">
                            <Avatar icon="pi pi-file" size="large" class="text-white bg-indigo-800" />
                          </div>
                          <div class="font-bold">
                            {{ $t("my_application") }}
                          </div>
                        </div>
                        <span class="text-500 text-sm"></span>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-12 md:col-6 sm:col-6">
                    <router-link :to="`applicants/results`">
                      <div class="card bg-green-100">
                        <div class="text-center">
                          <div class="mb-3" style="width: auto">
                            <Avatar icon="pi pi-id-card" size="large" class="text-white bg-green-500" />
                          </div>
                          <div class="font-bold">
                            {{ $t("application_results") }}
                          </div>
                        </div>
                        <span class="text-500 text-sm"></span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 sm:col-12 comp-grid">
            <div v-if="user.application_type == postgraduate">
              <api-data-source :api-path="`applicants/recommendation_letters?email=${recommendation_email}`" v-slot="req">
                <div v-if="req.loading" class="p-3 text-center">
                  <ProgressSpinner style="width: 30px; height: 30px" />
                </div>
                <div class=" p-0" v-if="!req.loading">
                  <div v-if="req.response" class="">
                    <div class="card py-3">
                      <div class="text-md font-medium"> Recommendation Letter(s) </div>
                    </div>
                    <div class="col-12 md:col-12 card p-0">
                      <DataTable :value="req.response" :showGridlines="false" :rowHover="true" responsiveLayout="stack"
                        class="p-datatable-small">
                        <Column field="name" header="Referee">
                          <template #body="slotProps">
                            <div> {{ slotProps.data.name }} </div>
                            <div class="text-sm text-purple-400 hidden-xs hidden-sm">{{ slotProps.data.position }}</div>
                            <div class="text-xs text-green-600 hidden-xs hidden-sm">{{ slotProps.data.institution }}</div>
                            <div class="text-xs font-bold hidden-xs hidden-sm">{{ slotProps.data.email }}</div>
                          </template>
                        </Column>
                        <Column field="email_status" header="Status">
                          <template #body="slotProps">
                            <div class="text-sm">
                              <Badge size="small" style="font-size: 0.7rem" class="" :value="slotProps.data.email_status"
                                :severity="slotProps.data.email_status == received ? 'success' : slotProps.data.email_status == sent ? 'info' : 'warning'">
                              </Badge>
                            </div>
                          </template>
                        </Column>
                        <Column field="letter" header="Action">
                          <template #body="slotProps">
                            <div v-if="slotProps.data.email_status == pending">
                              <Button label="Send Email" class="p-button-warning p-button-text text-yellow-500 p-0"
                                style="font-size: 13px" @click="sendRecommendationLetter(slotProps.data.email)" />
                            </div>
                            <div v-if="slotProps.data.email_status == sent">
                              <Button label="Send Reminder" class="p-button-success p-button-text text-green-500 p-0"
                                style="font-size: 13px" @click="sendRecommendationLetter(slotProps.data.email)" />
                              <div class="text-xs" v-if="slotProps.data.last_email_sent">
                                Last email sent on {{ $utils.humanDatetime(slotProps.data.last_email_sent) }}
                              </div>
                            </div>
                            <div v-if="slotProps.data.email_status == received">
                              <span class="text-sm text-green-500">Completed</span>
                              <div class="text-xs" v-if="slotProps.data.last_email_sent">
                                Received on {{ $utils.humanDatetime(slotProps.data.received_date) }}
                              </div>
                            </div>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </api-data-source>
            </div>
            <div class="card nice-shadow-18">
              <div class="flex">
                <div class="mr-3 hidden-xs">
                  <Avatar style="background-color: #c9c9d0; color: #292866" icon="pi pi-question" shape="circle"
                    size="large" />
                </div>
                <div class="mb-2">
                  <div class="text-md mb-2">
                    For any queries please contact Admissions Office Main
                    Hotline/Whatsapp Number <span class="pi pi-whatsapp"></span> <b>+233501579732</b>. Thank you
                  </div>
                  <div class="mb-2"><b>Email:</b> info@gtuc.edu.gh </div>
                  <div class="mb-2"><b>Tel:</b> +233(0) 302221446 / 302221412 / 302221479</div>
                  <div class="mb-2"><b>Skype:</b> gtuconline </div>
                  <div class="mb-2"><b>Digital Adress:</b> GA-167-2979 </div>
                  <div class="mb-2 text-primary"> PMB 100, Tesano, Accra – Ghana </div>
                  <div class="mb-2">Off J.A Kuffour Avenue, <br />Adjacent the Police Training School,Tesano, Accra
                  </div>
                </div>
              </div>
            </div>
            <div class="card nice-shadow-18">
              <div class="flex">
                <div class="mr-3 hidden-xs">
                  <Avatar style="background-color: #c9c9d0; color: #292866" icon="pi pi-link" shape="circle"
                    size="large" />
                </div>
                <div class="mb-2">
                  <div class="text-md font-bold mb-2"> Useful Links </div>
                  <div>
                    <a target="_blank" href="https://site.gctu.edu.gh/">
                      <Button label="Main Website (site.gctu.edu.gh)"
                        class="p-button-sm p-button-link text-black px-0 text-left" icon="pi pi-link"></Button>
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://gs.gctu.edu.gh/">
                      <Button label="Graduate School (gs.gctu.edu.gh)"
                        class="p-button-sm p-button-link text-black px-0 text-left" icon="pi pi-link"></Button>
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://site.gctu.edu.gh/fees-schedule">
                      <Button label="Undergraduate Tuition Fees"
                        class="p-button-sm p-button-link text-black px-0 text-left" icon="pi pi-link"></Button>
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://gs.gctu.edu.gh/academics/masters-programmes/">
                      <Button label="Graduate School Tuition Fees"
                        class="p-button-sm p-button-link text-black px-0 text-left" icon="pi pi-link"></Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { ApiService } from "../../services/api";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "homePage",
  components: {},
  mixins: [PageMixin],
  props: {
    pageName: {
      type: String,
      default: "home",
    },
    routeName: {
      type: String,
      default: "homePage",
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  data: function () {
    return {
      ready: false,
      deadLine : null,
      successColor: "#087408",
      pendingColor: "#9f6810",
      closed: "Closed",
      paid: "Paid",
      completed: "Completed",
      postgraduate: "Postgraduate",
      pending: "Pending",
      received: "Received",
      sent: "Sent",
      recommendation_email: "",
    };
  },
  computed: {
    ...mapGetters("app_config", ["currentAppConfig"]),
    ...mapGetters("auth", ["user"]),
    pageTitle: {
      get: function () {
        return this.$t("welcome_to_caps");
      },
    },
  },
  methods: {
    editApplication(index) {
      let pageIndex = index;
      this.$router.push({
        name: "applicantsedit",
        params: { pageIndex },
        props: { pageIndex },
      });
    },
    sendRecommendationLetter(email) {
      this.recommendation_email = email
    },
    async getDeadLine() {
     await ApiService.get(`components_data/app_config?academic_year=${this.user.academic_year}&application_type=${this.user.application_type}`)
        .then((response) => {
        
          this.deadLine = this.$utils.humanDate(response.data[0].application_deadline)
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {},
  async mounted() {
    this.ready = true;
    await this.$store.dispatch("auth/getUserData");
    await this.$store.dispatch("app_config/fetchAppConfig");
    await this.getDeadLine()
    
  },
  async created() {
    // await this.$store.dispatch("auth/getUserData");
  },
};
</script>
<style lang="scss" scoped>
.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

::v-deep(.p-timeline-event-content) ::v-deep(.p-timeline-event-opposite) {
  line-height: 1;
}
</style>